:root {
  --ribbon-background: #00364d;
}

.ribbon-layout {
  height: 4rem;
  background-color: var(--ribbon-background);
  position: sticky;
  width: 100%;
  overflow: hidden;
  top: 0;
  z-index: 2;
}

.header {
  display: flex;
  background-color: var(--ribbon-background);
}

.logo {
  /* padding-top: 0.5%; */
}

.dot {
  margin-top: 12%;
  color: #86bc25;
}

.div-information-header{
  display: flex;
  margin-left: auto;
}

.star {
  color: #ffffff;
}
.star-image {
  cursor: pointer;
}
