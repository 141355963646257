.custom-span-logout {
    background-color: #00364d;
    border-color: #00364d;
    width: 14em;
    color: white;
    margin-left:0.5em;
    text-align: center;
}

.custom-span-logout:hover{
    cursor: pointer;
}

.dropdown-logout {
    position: relative;
    display: inline-block;
    margin-left: 0.5em;
    text-align: center;
}

.dropdown-content-logout {
    display: none;
    position: fixed;
    background-color: #f1f1f1;
    align-items: center;
    min-width: 11em;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
    max-height: 3.5em;
    text-align: left;
  }

  .dropdown-content-logout span {
    color: black;
    padding-left: 1em;
    text-decoration: none;
    display: block;
    margin-top: -0.5em;
  }

  .dropdown-logout:hover .dropdown-content-logout {display: block;}

  #spanLogout:hover{
    cursor: pointer;
  }