.add-user-modal-form {
  margin-top: 2.75rem;
}
.add-user-form-row {
  display: flex;
}
.add-user-form-item-1 {
  width: 50%;
  /* margin-right: 1rem; */
}
.add-user-form-item-2 {
  width: 90%;
  margin-right: 1rem;
  margin-left: 1.5rem;
}
.add-user-form-item-dropdown-1 {
  width: 45% !important;
  margin-right: 1rem;
}
.add-user-form-item-dropdown-2 {
  width: 45% !important;
  margin-right: 1rem;
}

.add-user-form-btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0rem;
}
.add-user-form-btn-1 {
  display: inline-block;
  margin-right: 1.5rem;
}

.add-op-textfield{
  width: 90%;
}
